@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pirata+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Creepster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Silkscreen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Henny+Penny&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Knewave&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pattaya&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');

.MuiFormControl-root {
  margin: 0;

}

@font-face {
  font-family: stitchFont;
  src: url(HovdenStitch-YzEvo.ttf);
}

@font-face {
  font-family: sniglet;
  src: url(Sniglet-Regular.ttf);
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.createCanvasDialogWrapper {
  display: flex;
  height: 100px;
  width: 475px;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
}

.trine {
  width: 430px;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 20%);
}

.inputTextTool {
  width: 600px!important;
}

#zoom-select {
  padding-right: 0px!important;
  padding-left: 0px!important;
}
#textToolCanvasWrapper {
  position: relative;
  display: flex;
  width: 600px;
  overflow: hidden;
  height: 300px;
  background: white;
  box-shadow: inset 0px 1px 5px rgba(0,0,0,0.3);
  border-radius: 4px;
  justify-content: center;
/*   background-image: url(../images/transparentpng.png);
  background-size: var(--textToolBackSize) var(--textToolBackSize); */
}

#textToolCanvasBack {

  width: inherit;
  height: inherit;
  position: absolute;
}

#textToolCanvasTextTemp {
  image-rendering: pixelated;
  visibility: hidden;
  position: absolute;
}

#textToolCanvasText {
  image-rendering: pixelated;
width: var(--textToolWidth);
  height: var(--textToolHeight); 
  position: absolute;
    top: 100px;
    left: 100px;
}

#textToolCanvas {
  
  object-fit: contain;
  max-width: 600px;
/*   background-image: url(../images/transparentpng.png);
  background-size: var(--textToolBackSize) var(--textToolBackSize); */
}

#textToolCanvasRulers {
 
  object-fit: contain;
  max-width: 600px;
}

.broderistWrapper {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 1200px;
}

.broderistWrapperLeft {
  max-width: 500px;
  padding-top: 32px;
  padding-bottom: 60px;
  text-align: left;
}

.trineCopyright{
  position: absolute;
  color: white;
  margin-top: -25px;
  margin-left: 9px;
  font-size: 10px;
}

.opdateringerWrapperLeft {
  padding-top: 32px;
  padding-bottom: 60px;
  text-align: left;
  padding-left: 40px;
    padding-right: 40px;
}

.infoTableSavedPattern {
  font-size: 18px;
 }

.callToActionBack {
  max-width: 420px;
  width: calc(var(--widthAndHeightSmall) + 40px);
  display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 84px;
    bottom: 0px;
    box-shadow: 0px -4px 12px rgb(0 0 0 / 20%)!important;
    border-radius: 0px!important;
}

.tableVersionsIcons {
  padding-top: 8px;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}
.savedPatternPreviewImageWrapper {
  width: 400px;
  height: 400px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  margin: 20px auto;
  align-items: center;
  box-shadow: 0 4px 12px rgb(0 0 0 / 20%);
}

.upgradeButtons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeButtons {
  margin-top: 48px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.savedPatternPreviewImage {
  width: 400px;
  image-rendering: pixelated;

}

@keyframes bouncing-loader {
  to {
    opacity: 0.35;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.right {
  display: flex;
}

.left {
  display: flex;
  align-items: center;
}

.smallKonfiguration {
  display: none;
}

.overskrift {
  max-width: 500px;
  margin: 40px auto 2px auto;
  height: 84px;
}

#stitchImage {
  position: absolute;
  visibility: hidden;
}

#pdfPointerTop {
  color: black!important;
  position: relative;
  top: -98px;
  left: -26px;
  height: 50px;
  transform: rotateZ(90deg);
  font-size: 50px;
}

.AvanceretRedigeringZoom {
  margin-right: 40px;
}

.AvanceretRedigeringVærktøjer {
  margin-left: 40px;
  margin-top: 20px;
}

.image-container-showcase {
  position: absolute;
  width: 300px;
  height: 300px;
  image-rendering: pixelated;
  background: white;
  border-radius: 12px;
  /* height: 300px; */
  box-shadow: 0 4px 12px rgb(0 0 0 / 20%);
  overflow: hidden;
/*   display: flex;
  align-items: center;
  height: 300px;
  width: 300px; */
  display: flex;
}


.colorPickerDiv {
  width: 20px;
  height: 20px;
  border-radius: 64px;
  margin: 3px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.2)!important;
  transition: transform 0.1s;
  cursor: pointer;
}
.colorPickerDiv:hover {
  transform: scale(1.2);
}

#chosenColor {
  margin: auto;
  margin-bottom: 20px;
  max-width: 230px;
}

.chosenColorCard {
  width: 300px;
  padding: 5px 10px;
  box-shadow: 0 4px 64px rgba(0,0,0,0.2)!important;
  border-radius: 8px;
  margin: auto;
}

.chosenColorTableDivider {
  margin-left: 0 !important;
    margin-right: -144px !important;
    margin-bottom: 6px!important;
}

.chosenColorTable {
  padding: 5px 10px;
  width: 214px;
}

.AdvancedEditCard {
  width: 120px!important;
  /* border-radius: 64px!important; */
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.colorPickerWrapper {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}

#colorPicker {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}

#redWrapper {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}

#yellowWrapper {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}

#greenWrapper {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}

#blueWrapper {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}

#violetWrapper {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}
#brownWrapper {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}

#grayWrapper {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}


#redWrapperReplace {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

#yellowWrapperReplace {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

#greenWrapperReplace {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

#blueWrapperReplace {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

#violetWrapperReplace {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}
#brownWrapperReplace {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

#grayWrapperReplace {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

.overskriftTekst {
  margin-bottom: 8px!important;
}

.sensitiveContentWrapper {
  position: absolute;
  display: grid;
  place-items: center;
}

.squareResizer {
  width: 240px;
  height: 240px;
  border: 1px solid rgba(0,0,0,.3);
  margin: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
}

.inputResizer {
  max-width: 100px!important;
}

.AdvancedEditCardColorPicker {
  margin-top: 60px;
    width: 400px!important;
    height: 500px;
   /*  border-radius: 36px!important; */
}

.tButtonAdvanced {
  border-radius: 48px!important; 
    border: 0!important;
    margin: 4px!important;
    width: 50px;
    height: 50px;
}

.buttonZoom {
  border: 0!important;
  border-radius: 48px!important; 
    margin: 4px!important;
    width: 50px;
    height: 50px;
}

.startChoice {
  margin: 70px;
}

.drawGuideElement{
  pointer-events: none;
}
.drawGuideElementCenter {
  pointer-events: none;
  position: absolute;
  width: inherit;
  height: inherit;
  top: -1px;
    left: -1px;
}

#drawRulerXWrapper {
  color: rgba(255,255,255,0.5);
  display: grid;
  margin-top: 10px;
  height: 40px;
}

#drawRulerYWrapper {
  color: rgba(255,255,255,0.5);
position: absolute;
left: -30px;
  display: flex;
  margin: auto 20px auto 0px;
  width: 40px;
}

#drawRulerXLineWrapper {

  border-radius: 4px;
  display: flex;
}

.showcaseStats {
  z-index: 1000;
}

#drawRulerYLineWrapper {

  border-radius: 4px;
  display: grid;
  place-items: end;
  align-items: flex-start;
}

#drawRulerXNumberWrapper {
  display: flex;
  justify-content: flex-start;

}

#drawRulerYNumberWrapper {
  display: grid;
  
}
.buttonFilterMobile {
  display: none!important;

  font-size: 0.8675rem!important;
  margin-right: 10px!important;
}

.drawDiv {
  width: var(--drawzoom-pixels);
  height: var(--drawzoom-pixels);
  z-index: 199;
  display: flex;
  justify-content: center;
  align-items: self-start;
  overflow: hidden;
  font-family: sniglet;
  text-align: center;
  line-height: var(--drawzoom-stitchLine);
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}

.advancedEditButtonDiv {
  max-width: 360px;
  margin: auto;
}

.shareCategoryWrapper {
  border: 1px solid;
  min-height: 56px;
  border-radius: 4px;
  border-color: rgba(255, 255, 255, 0.23);
}

.sharePreviewImage {
  height: 140px;
    image-rendering: pixelated;
    box-shadow: 0px 5px 16px rgb(0 0 0 / 20%);
}

.sharePreviewImageWrapper {
  /* background: rgb(44,44,44); */
    box-shadow: 0px 0px 6px inset rgb(0 0 0 / 30%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
}

.shareCategoryTextfieldText {
  /* color: rgba(255, 255, 255, 0.7); */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently*/
}

#drawerWrapper {
  display: grid;
  grid-template-columns: repeat(var(--drawergrid-cols), 1fr);
  z-index: 200;
  position: absolute;
  visibility: hidden;
  /*background: #202020;*/
}

#drawerWrapperCanvasTop {
  position: absolute;
  z-index: 10;
}
#drawerWrapperCanvas {
  display: flex;
  image-rendering: pixelated;
}

.cRounded {
  width: 50px;
  height: 50px;
  border: 2px solid white;
  border-radius: 64px 64px 64px 0px;
  margin-left: 1px;
  margin-top: -49px;
  background-color: white;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 20%);
  z-index: 1000;
  pointer-events: none;
  position: fixed;
  visibility: hidden;
  transform: translate(0px, 0px);
  transition: height .3s cubic-bezier(0.46,0.03,0.52,0.96), width .3s cubic-bezier(0.46,0.03,0.52,0.96);
}



.eraser {
  width: 28px;
  opacity: 0.55;
}

.custom-cursor {
  width: 50px;
  border: 2px solid white;
  border-radius: 64px 64px 64px 0px;
  margin-left: 26px;
  margin-top: -24px;
  background-color: white;
  opacity: 1!important;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 20%);
  visibility: hidden;
}

#colorPickButton {
  transition: opacity 0.2s;
    cursor: pointer;
    background: black;
    color: white;
    opacity: 0;
    border-radius: inherit;
    width: 52px;
    height: 25px;
}

#colorPickButton:hover {
  opacity: 0.2;
}

.topNavigationWrapper {
  display: flex;
  justify-content: space-around;
  max-width: 1472px;
  margin: auto;
  margin-bottom: 18px;
}

.topNavigationButtonWrapper {
  display: flex;
  justify-content: center;
  width: 600px;
}

.drawGridSettingsWrapper {
    position: absolute;
    left: 50px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 136px;
    top: -38px;
    
    width: 0px;
    opacity: 0;
    transition: opacity .15s cubic-bezier(0.46,0.03,0.52,0.96)!important;
}

.drawGridSettingsCard {
  box-shadow:  0px 4px 64px rgb(0 0 0 / 20%)!important;
  height: 136px;
  width: 148px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.drawGridSettingsWrapper:hover {
  opacity: 1;
  width: 174x;

}


.drawGridButton {
  border: 0!important;
  border-radius: 48px!important; 
    margin: 4px!important;
    width: 50px;
    height: 50px;
}

/* .drawGridButton:hover {
  width: 200px;
} */

/* .drawGridButton:hover + div {
  opacity: 1;
  width: 207px;
} */

.cPointed {
  width: 7px;
  height: 7px;
  background-color: white;
  border-radius: 50%;
  z-index: 1000;
  pointer-events: none;
  position: fixed;
  visibility: hidden;
  transform: translate(0px, 0px);
  transition: height .3s cubic-bezier(0.46,0.03,0.52,0.96), width .3s cubic-bezier(0.46,0.03,0.52,0.96);
}
.catalogItemPageNavigation {

  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
}

.catalogItemPageEdit {

  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
}

.catalogItemPageWrapper {
display: flex;
justify-content: center;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid rgba(233, 30, 99, 0.5);
  display: flex;
  justify-content: center;
  padding: 7px 21px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  transition: background-color .25s ease-out,border .25s ease-out;
  text-transform: uppercase;
}

.custom-file-upload:hover {
  background-color: rgba(233, 30, 99, 0.05);
  border: 1px solid rgba(233, 30, 99, 1);
}

.imageShareUploadWrapper {
  width: 200px;
    height: 200px;
    margin: 20px auto auto;
    position: relative;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 20%);
}

.catalogItemPageImageWrapper {
  width: 600px;
  height: 600px;
  border-radius: 12px;
  overflow: hidden;
/*   box-shadow: 0px 4px 12px rgb(0 0 0 / 20%); */
  box-shadow: 0px 8px 24px rgb(0 0 0 / 30%);
}
.topSortWrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 50px 30px 0px;
  align-items: center;
}

.topFilterWrapper {
  display: flex;
  justify-content: flex-start;
  padding: 0px 50px 30px 50px;
  align-items: center;
}

.catalogFilterWrapper {
  height: 100%;
  width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 80px;
  text-align: left;
  padding: 110px 50px;
}

.selectInputSort{
  text-align: left;
    box-shadow: 0px 4px 6px rgb(0 0 0 / 15%);
    width: 160px;
}

.catalogItemPageImage {
  image-rendering: pixelated;
  width: inherit;
  height: inherit;
  object-fit: contain;
}

.backgroundDiv {
  /* padding-left: 18px; */
}

.catalogItemPageImageWraper {
  width: 600px;
  height: 600px;
  margin: 0px 40px 40px 40px;
}

.catalogItemImageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: 210px;
  width: 210px;
  border-radius: 12px;
  pointer-events: none;
  

}

.catalogItemPaper {
  
}

.catalogMarginLeftDiv {
  margin-left: 300px;
  text-align: left;
}

.favoriteButton {
  border-radius: 64px;
  box-shadow: 0px 4px 12px rgba(0 0 0 / 20%);
  position: absolute;
  top: 10px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 0;
  transition: opacity 300ms ease-out, top 300ms ease-out;

}

.favoriteButtonCatalogItem {
/*   border-radius: 64px;
  box-shadow: 0px 4px 12px rgba(0 0 0 / 20%); */
/* width: 1.5em!important;
height: 1.5em!important; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: 16px;
  opacity: 0.7;
  transition: opacity 150ms ease-out;
}

.favoriteButtonCatalogItem:hover {
  opacity: 1;
}



.commentWrapper {
  text-align: left;
  margin-bottom: 32px;
}

.commentsWrapper {
  margin-top: 30px;
}

.commentAuthorWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 8px;
}

.commentSectionHeadline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.writeCommentAuthor {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 8px;
}

.writeCommentButtonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.writeCommentWrapper {
  margin-bottom: 10px;
}

.commentTextWrapper {
  margin-left: 32px;
/*   text-overflow: ellipsis;
  white-space: break-spaces;
  overflow: hidden; */
  white-space: normal;
word-wrap: break-word;
}

.catalogItemSelector:hover + div {
  top: 20px;
  opacity: 1;
}

.favoriteButton:hover {
  transform: scale(1.1);
  top: 20px;
  opacity: 1;
}

.favoriteButtonFavorited {
  border-radius: 64px;
  box-shadow: 0px 4px 12px rgba(0 0 0 / 20%);
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 1;
  transition: opacity 300ms ease-out, top 300ms ease-out;

}

.favoriteButtonFavorited:hover {
  transform: scale(1.1);
}

.hovedKnapper {
  margin: 10px; 
 padding-bottom: 20px;
 display: none;
}

.catalogItemImage {
  image-rendering: pixelated;
  width: 210px;
  height: 210px;
  object-fit: contain;
  filter: blur(0.2px);
}


.splide__pagination__page.is-active {
  background: var(--primary)!important;
}

.catalogItemImageBackground {
  visibility: hidden;
  position: absolute;
  image-rendering: auto;
  width: 210px;
  height: 210px;
  object-fit: contain;
  border-radius: 12px;
}

.catalogItemImageHideOnHover {
  position: absolute;
  image-rendering: auto;
  width: 210px;
  height: 210px;
  object-fit: contain;
  /* border-radius: 12px; */
  z-index: 1;
  /* background-color: white; */
}

.catalogItemSelector {
  color: unset;
  text-decoration: none;
  width: inherit;
  position: absolute;
  height: inherit;
  border-radius: 12px;
  cursor: pointer;
  background-color: rgba(0 0 0 / 0%);
  transition: background-color 0.1s;
  overflow: hidden;
}

.catalogItemSelector:hover .savedPatternsDropPointerDiv {
  opacity: .2;;
}

.catalogItemSelector:hover .catalogItemWrapperDiv .catalogItemImageWrapper .catalogItemImageHideOnHover  {
  visibility: hidden;
  position: absolute;

}

.catalogItemSelector:hover .catalogItemWrapperDiv .catalogItemImageWrapper .catalogItemImageBackground  {
  visibility: visible;
  position: block;

}


.catalogWrapper {
  /* margin: auto; */
  padding: 0px 50px 50px 0px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  place-items: flex-start; 
}

.catalogItemLoadWrapper {
  width: 240px;
    height: 330px;
    padding: 15px;
}


#pdfPointerLeft {
  color: black!important;
  position: relative;
  top: -82px;
  left: -42px;
  height: 50px;
  font-size: 50px;
}

* {
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari, Edge */
  color-adjust: exact !important;
  /*Firefox*/
}

.a4Page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 0cm 1.5cm;
  margin: 1cm auto;
  /*border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);*/
}

.no-flickr {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

#patternPdfDiv {
  width: 600px;
  height: 600px;
  margin: 0px;
  position: absolute;
  display: flex;
}

.mønsterOpdelingOverskrift {
  color: black;
  font-size: 20px;
  font-weight: 400;
}

.pdfTableCaption {
  color: black;
  width: inherit;
  font-size: 20px;
  margin-bottom: 12px;
  display: block;
}
.mobileOverskrift {
  line-height: 0px;
    margin-bottom: 10px;
}





@media print {
  #downloadPDFDiv {
    visibility: visible !important;
  }

  #patternPdfDiv,
  #pdfCanvasPattern {
    max-height: none !important;
    height: calc(inherit*6) !important;
    max-width: none !important;
    width: calc(inherit*6) !important;
  }

  .a4Page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    page-break-after: always;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}

#pdfPointerTopNoLabel {
  color: black!important;
  position: relative;
  top: -42px;
  left: -27px;
  height: 50px;
  transform: rotateZ(90deg);
  font-size: 50px;
}

.mønsterOpdeling {
  display: grid;
  position: absolute;
  grid-template-columns: repeat(var(--previewgrid-cols), 1fr);
}

.mønsterOpdelingA4 {
  border: 1px solid rgba(0 0 0 / 40%);
  background-color: rgba(255, 255, 255, 30%);
}

.mønsterOpdelingA4Label {
  color: rgba(0 0 0 / 70%);
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  float: left;
  margin-left: 10px;
}

.pdfRedLineHorizontal {
  border: 2px solid red;
  position: relative;
  top: -118px;
  z-index: 1;
}

#pdfRedLineHorizontalNoLabel {
  top: -61px !important;
}

.pdfRedLineVertical {
  border: 2px solid red;
  position: relative;
  top: -114px;
  left: -4px;
  width: 0px;
  z-index: 1;
}

.pdfBlackLineVertical {
  border: 2px solid black;
  position: relative;
  top: -56px;
  left: -4px;
  width: 0px;
}

.pdfBlackLineHorizontal {
  border: 2px solid black;
  position: relative;
  top: -3px;
}
.infoTable {
  width: 100%;
  padding: 40px 40px;
  border-spacing: 0;
}
#pdfRedLineVerticalNoLabel {
  top: -58px !important;
}

#pdfPointerLeftNoLabel {
  color: black!important;
  position: relative;
  top: -26px;
  left: -42px;
  height: 50px;
  font-size: 50px;
}

.pdfStitchLabelTop {
  display: flex;
  justify-content: center;
  position: relative;
  top: -50px;
  left: -10px;
  color: black;
  font-weight: bold;
}
.savedPatternsDropWrapper {
  margin-top: 80px;
}
.savedPatternsDropImageDiv {
  overflow: hidden;
  /* height: 200px;
  width: 200px; */
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
}

.savedPatternsImageDiv {
  overflow: hidden;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
}

.savedPatternsDropItem {
  border-radius: 12px!important;
  box-shadow: 0 4px 12px rgba(0,0,0,0.2)!important;
  /* width: 200px;
  min-height: 200px; */
  width: 100px;
  min-height: 100px;
  transition: transform .25s ease-out, box-shadow .25s ease-out!important;
}

.savedPatternsDropItem:hover {
  transform: translate(0px, -4px);
  box-shadow: 0 12px 28px rgba(0,0,0,0.15)!important;
}

.savedPatternsDropItem:hover .savedPatternsDropPointerDiv {
  opacity: 0.2;
}

.savedPatternsDropItemWrapper {
/*     display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    width: 450px;
    margin: 20px auto; */
    display: flex;
    margin: 20px auto;
    align-items: center;
    justify-content: space-around;
}

.savedPatternsDropItemWrapper3 {
      display: flex;
      margin: 20px auto;
      align-items: center;
      justify-content: space-around;
      width: 370px!important;
  }


  .catalogPointerDiv {
    width: inherit;
    position: absolute;
    height: inherit;
    border-radius: 12px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bolder;
  }
  
  .catalogPointerDiv:hover {
    opacity: 0.04;
  }
  
  .catalogPointerDivBlack {
    background: rgb(255,255,255);
    color: black;
  }
  
  .catalogPointerDivWhite {
    background: rgb(0,0,0);
    color: white;
  }


.savedPatternsDropPointerDiv {
  width: inherit;
  position: absolute;
  height: inherit;
  border-radius: 12px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bolder;
}

.savedPatternsDropPointerDiv:hover {
  opacity: 0.2;
}

.savedPatternsDropPointerDivBlack {
  background: rgb(255,255,255);
  color: black;
}

.savedPatternsDropPointerDivWhite {
  background: rgb(0,0,0);
  color: white;
}

.savedPatternsTableActionButtons {
 box-shadow: 0px -2px 12px rgb(0 0 0 / 10%);
 display: flex;
 justify-content: space-evenly;
 align-items: center;
 padding: 14px;
}


.pdfStitchLabelEndTop {
  display: flex;
  justify-content: center;
  position: relative;
  top: -50px;
  left: 8px;
  color: black;
  font-weight: bold;
}

.imageParent {
  margin: auto;
  width: fit-content;
}

#printImage {
  display: block;
  margin: auto;
}
.hovedKnapperBack {
  border-radius: 0px!important;
  display: none;
  position: fixed;
  bottom: 0px;
  height: 100px;
  width: 100%;
  z-index: 3;
  box-shadow: 0px -4px 12px rgba(0 0 0 / 20%)!important;
}

.pdfStitchLabelBottom {
  display: flex;
  justify-content: center;
  position: relative;
  top: 10px;
  left: -10px;
  color: black;
  font-weight: bold;
}

.pdfStitchLabelEndBottom {
  display: flex;
  justify-content: center;
  position: relative;
  top: 10px;
  left: 8px;
  color: black;
  font-weight: bold;
}


#tableToPrint {
  /* margin: auto;
  width: fit-content;
  padding: 30px; */
  width: inherit;
  /* margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.pdfTableRow {
  color: black;
  height: 22px;
  page-break-inside: 'avoid'
}

.pdfTableRowBig {
  color: black;
  height: 22px;
  page-break-inside: 'avoid'
}

.korsstingHjerte {
  font-size: 60px;
  margin: 0px 12px 16px 15px;
  letter-spacing: -14px;
  transform: rotateZ(270deg);
  font-family: stitchFont;
  color: var(--primary);
  line-height: 60px;
}

.mønsterSkrift {
  font-size: 60px;
  margin: 0px 12px 16px 15px;
  font-family: stitchFont;
  line-height: 60px;
}

.progressDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 135px;
}

.cropperClass {
  margin: auto;
  height: 600px;
  width: 600px;
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: pixelated;
}

.rotateSlider {
  width: 500px;
}
.cookieDetaljer {
  box-shadow: 0px 0px 0px rgba(0 0 0 / 0%)!important;
}

.cookieDetaljer::before {
  background-color: rgba(0 0 0 / 0%)!important;
}

/* body{
  background: url(../images/background.svg) repeat center center fixed; */
/*   -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  /*background-position-x: 228px;
    background-position-y: 28px;
} */

.transparentBackground {
  background-image: url(../images/transparentpng.png);
  position: absolute;
  top: 0;
  z-index: -3;
  background-size: var(--drawzoom-pixels) var(--drawzoom-pixels);
  width: 100%;
  height: 100%;
}

.marieSytråd {
  background: url(../images/mariesy.svg);
  position: absolute;
  top: -326px;
  left: -487px;
  width: 617px;
  height: 724px;
  transform: rotate(257deg) scale(0.6);
}

.marieSytrådDesignVærksted {
  background: url(../images/mariesy.svg);
  position: absolute;
  top: -437px;
  left: -37px;
  width: 615px;
  height: 952px;
  transform: rotate(270deg) scaleX(-1) scale(-0.25);
}

.marieSytrådWrapper {
  position: relative;
}

.sliderReset {
  position: relative !important;
  top: -11px;
  left: -132px;
  height: 22px !important;
  margin-bottom: 0.5em !important;
  margin-left: 10px !important;
  opacity: 0.5;
}



.upscaledCanvas1to1PDF {
  z-index: -1;
  width: calc(var(--cropWidth)*6px);
  height: calc(var(--cropHeight)*6px);
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

.upscaledCanvas1to1 {
  z-index: -1;
  width: calc(var(--cropWidth)*6px);
  height: calc(var(--cropHeight)*6px);
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

/* .resultat {
  width: 600px;
} */

.upscaledCanvas16to9 {
  position: absolute;
  margin: auto;
  z-index: -1;
  width: 600px;
  height: 337.5px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

.upscaledCanvas5to4 {
  position: absolute;
  margin: auto;
  z-index: -1;
  width: 600px;
  height: 480px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

#quantDiv {
  position: absolute;
  width: 600px;
  height: 600px;
  justify-content: center;
  align-items: center;
  display: grid;
  z-index: -1000;
}

.resultCard {
  z-index: 1;
  position: relative;
  width: 600px;
  box-shadow: 0px 4px 64px rgb(0 0 0 / 20%) !important;
}

.formControlInput {
  margin: 10px !important;
  min-width: 100px !important;
  max-width: 210px;
}

.beskærOgResultat {
  display: inline-flex;
  width: 100%;
  max-width: 1472px;
  justify-content: space-around;
  align-items: baseline;
}

.quantCanvas {
  width: 600px;
  height: 594px;
  position: absolute;
}
.quantCanvasBackground {
  background-color: rgba(0 0 0 / 50%);
  width: 600px;
  height: 600px;
  position: absolute;
  z-index: -2000;
}

.quantCanvasPDF {
  width: 600px;
  height: 594px;
  position: relative;
}

.selectInputStatic {
  text-align: left;
  box-shadow: 0px 4px 6px rgb(0 0 0 / 15%);
  width: 210px;
}

.selectInput {
  text-align: left;
  box-shadow: 0px 4px 6px rgb(0 0 0 / 15%);
  width: 210px;
}

.selectInputSymbolSetting {
  text-align: left;
  box-shadow: 0px 4px 6px rgb(0 0 0 / 15%);
}

.selectInputLanguages {
  box-shadow: 0px 4px 6px rgb(0 0 0 / 15%);
}

.selectSymbols {
  width: 67px;
  height: 32px;
  margin: 1px;
}

.alleTalAccordion {
  width: 470px;
  box-shadow: 0px 4px 6px rgb(0 0 0 / 15%) !important;
}

.socialMediaWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 120px;
  margin: auto;
}

.helpAccordion {
  width: 470px;
  box-shadow: 0px 4px 6px rgb(0 0 0 / 15%) !important;
}

.patternPreviewDone {
  margin: 0px 0px;
}

#patternPreviewDoneDiv {
  position: absolute;
  width: 600px;
  height: 600px;
  justify-content: center;
  align-items: center;
  display: grid;
}


#drawerWrapperCanvasTopSelection {
  position: absolute;
  width: 600px;
  height: 600px;
  display: grid;
  place-content: baseline;
}

.selectionBox {

  position: absolute;
  z-index: 1000;
  cursor: move;
}

.selectionBoxBorderDiv {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  border-width: var(--drawzoom-pixels);
    border-color: white;
    border-style: dashed;
    mix-blend-mode: difference;
}

.hjælpetekstLilleSkærm {
  display: none !important;
}

.hjælpetekstStorSkærm {
  display: block !important;
}

.dmcTableContainer {
  width: 600px !important;
  max-height: 420px !important;
}

#drawGuide {
  display: grid;
  pointer-events: none;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.1);
  --drawergrid-cols: 30;
    --drawzoom-pixels: 20px;
    --drawzoom-stitches: 2.5em;
    --drawzoom-stitchLine: 0.9rem;
  grid-template-columns: repeat(var(--drawergrid-cols), 1fr);
}

#drawGuide div {
  width: var(--drawzoom-pixels);
  height: var(--drawzoom-pixels);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.dmcTableContainerDrawColor {
/*   width: 345px!important; */
  max-height: 300px !important;
  overflow-x: hidden!important;
}

.savedPatternsTable::-webkit-scrollbar{
  width: 10px;
}

.savedPatternsTable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0 0 0 / 40%);
  transition: background-color 0.2s;
  }

.savedPatternsTable::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0 0 0 / 50%);
}

.categoryClass::-webkit-scrollbar{
  width: 10px;
}

.categoryClass::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0 0 0 / 20%);
  transition: background-color 0.2s;
  }

.categoryClass::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0 0 0 / 30%);
}


.manualWrapper::-webkit-scrollbar{
  width: 10px;
}

.manualWrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0 0 0 / 20%);
  transition: background-color 0.2s;
  }

.manualWrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0 0 0 / 30%);
}

.dmcTableContainerDrawColor::-webkit-scrollbar {
  width: 10px;
  }

.dmcTableContainerDrawColor::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0 0 0 / 20%);
  transition: background-color 0.2s;
  }

  .dmcTableContainerDrawColor::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0 0 0 / 30%);
  }


  .colorPickerDialog::-webkit-scrollbar {
    width: 10px;
    }
  
  .colorPickerDialog::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0 0 0 / 20%);
    transition: background-color 0.2s;
    }
  
    .colorPickerDialog::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0 0 0 / 30%);
    }

.colorPickerReplace {
  max-width: 328px!important;
  max-height: 430px!important;
  overflow-y: auto!important;
  padding: 7px!important;

}

.colorPickerReplace::-webkit-scrollbar {
  width: 10px;
  }

  .colorPickerReplace::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0 0 0 / 20%);
  transition: background-color 0.2s;
  }

  .colorPickerReplace::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0 0 0 / 30%);
  }

.doneIcon {
  animation-duration: 1s;
  animation-name: bounceIn;
}

@keyframes bounceIn {

  0% {

    transform: scale(0.1);

    opacity: 0;

  }

  40% {

    transform: scale(0.1);

    opacity: 0;

  }

  70% {

    transform: scale(1.3);

    opacity: 1;

  }

  100% {

    transform: scale(1);
  }

}

.dmcTablePaper {
  max-width: 600px;
  margin-bottom: 2px;
  box-shadow: 0px 4px 64px rgb(0 0 0 / 20%) !important;
  overflow: hidden !important;
}

.dmcTablePaperDrawColor {
  max-width: 520px;
  box-shadow: 0px 4px 64px rgb(0 0 0 / 20%) !important;
  overflow: hidden !important;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 8px!important;
}

.savedPatternsTablePaper {
  max-width: 600px;
  margin-bottom: 2px;
  box-shadow: 0px 4px 64px rgb(0 0 0 / 20%) !important;
  overflow: hidden !important;
}

.oversigtTop {
  /* height: 950px; */
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  max-width: 1472px;
  margin: auto;
}
#drejBilledeOverskrift {
  margin-bottom: -26px;
}

.infoTableDivider {
  margin-left: 0 !important;
  margin-right: -260px !important;
}

.infoTableDividerSavedPattern {
  margin-left: 0 !important;
  margin-right: -200px !important;
}

.dropClass {
  width: 500px;
  height: 500px;
  border-radius: 24px;
}

.dropClass:hover {
  filter: brightness(1.25);
}

.dropClassLight {
  width: 500px;
  height: 500px;
  border-radius: 24px;
}
.doneButtonLarge{
  display: inline-flex!important;
  width: 304px;
}
.doneButtonSmall {
  display: none!important;
  position: absolute!important;
}

.buttonSmallWrapper {
  position: absolute!important;
}
#overskriftTekst {
  display: block;
}

.overskriftTekstSmall {
  display: none;
}
#underskriftTekst {
  display: initial;
}

.underskriftTekstSmall {
  display: none;
  font-size: 0.9rem !important;
}

.sizeRegulatorDivA4 {
  width: 1000px;
  height: 1410px;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.dropClassLight:hover {
  filter: brightness(0.85);
}
.dividerKlasse {
  margin-top: 6px!important;
}

.inputDivider {
    margin-top: 22px !important;
}

.errorDiv {
  width: 600px;
  height: 600px;
  position: absolute;
  background-color: rgba(255,255,255,90%);
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.loadingDiv {
  width: 600px;
  height: 600px;
  position: absolute;
  background-color: rgba(0,0,0,70%);
  align-items: center;
  justify-content: center;
}

@media (max-height: 1250px) {
  .savedPatternsDropWrapper {
    margin-top: 40px;
  }
  .dropClassLight {
    width: 300px;
    height: 300px;
    border-radius: 14px;
  }
  .savedPatternsDropItem {
    width: 100px!important;
    min-height: 100px!important;
  }
  .savedPatternsDropImageDiv {
    width: 100px;
    height: 100px;
  }
  .savedPatternsDropItemWrapper {
    width: 260px!important;
  }
  .savedPatternsDropItemWrapper3 {
    width: 370px!important;
  }


  .dropClass {
    width: 300px;
    height: 300px;
    border-radius: 14px;
  }
}

@media (max-height: 900px) {

  .dropWrapper {
    top: 440px !important;
  }

/*   .dropClass {
    position: absolute !important;
    top: 530px !important;
  } */
}



@media (max-width: 1460px) {
.topNavigationWrapper {
  max-width: 1100px;
}
.topNavigationButtonWrapper {
  width: 400px;
}
/* .resultat {
  width: 400px;
} */

  .marieSytrådDesignVærksted {
    top: -343px;
    left: -51px;
    width: 533px;
    height: 755px;
  }

  .savedPatternsDropWrapper {
    margin-top: 40px;
  }
  .savedPatternsDropItem {
    width: 100px!important;
    min-height: 100px!important;
  }
  .savedPatternsDropImageDiv {
    width: 100px;
    height: 100px;
  }
  .savedPatternsDropItemWrapper {
    width: 240px!important;
  }
  .savedPatternsDropItemWrapper3 {
    width: 370px!important;
  }
  #drejBilledeOverskrift {
    margin-bottom: -4px;
  }
  .loadingDiv {
    width: 400px;
    height: 400px;
  }
  .errorDiv {
    width: 400px;
    height: 400px;
  }

  .dividerKlasse {
    margin-top: 28px!important;
  }
  .tButtonGroup {
    height: 40px!important;
  }
  .tButton {
    font-size: 12px!important;
    line-height: 18px!important;
    padding: 0px 10px!important;
  }

  .dropClassLight {
    width: 300px;
    height: 300px;
    border-radius: 14px;
  }

  .dropClass {
    width: 300px;
    height: 300px;
    border-radius: 14px;
  }

  .infoTableDivider {
    margin-left: 0 !important;
    margin-right: -160px !important;
  }

  .dmcTableRowText {
    font-size: 0.7rem !important;
  }
  .dmcTableRowTextHide {
    display: none !important;
  }

  .dmcTableHeadHide {
    display: none !important;
  }

/*   .dmcTablePaper {
    max-width: 450px;
  } */

  .dmcTableContainer {
    width: 400px !important;
  }

  .dmcTablePaper {
    max-width: 400px;
  }

  .hjælpetekstLilleSkærm {
    display: block !important;
  }

  .hjælpetekstStorSkærm {
    display: none !important;
  }

  .cropperClass {
    height: 400px;
    width: 400px;
  }

  .rotateSlider {
    width: 320px;
  }

  .konfiguration {
    max-width: 360px;
  }

  .drawerKonfiguration {
    width: var(--widthAndHeightSmall);
    max-width: 400px;
  }

  .slider {
    max-height: 60px;
    align-items: center !important;
  }

  .sliderReset {
    top: -8px;
    left: -56px;
  }

  .overskrift {
    margin: 26px auto 0px auto;
    height: 88px;
  }

  .overskriftTekst {
    font-size: 1.5rem !important;
  }
  .overskriftTekstSmall {
    font-size: 1.5rem !important;
  }
  .underskriftTekst {
    font-size: 0.9rem !important;
  }

  .cardOverskrifter {
    font-size: 1.2rem !important;
  }

  .upscaledCanvas1to1 {
    width: calc(var(--cropWidth)*4px);
    height: calc(var(--cropHeight)*4px);
  }

  #quantDiv {
    width: 400px;
    height: 400px;
  }

  .resultCard {
    width: 400px;
  }

  .beskærOgResultat {
    max-width: 1066px;
    justify-content: space-evenly;
  }

  .quantCanvas {
    width: 400px;
    height: 394px;
  }
  .quantCanvasBackground {
    width: 400px;
    height: 400px;
  }

  .selectInput {
    width: 140px;
  }

  .formControlInput {
    margin: 6px !important;
    max-width: 140px;
  }

  .alleTalAccordion {
    min-height: 35px;
    max-width: 310px;
  }

  .lilleXIMidten {
    margin: -14px !important;
  }

  .inputDivider {
    margin-bottom: 9px !important;
  }

  .oversigtTop {
    /* margin-top: 20px; */
    /* height: 780px; */
    max-width: 1066px;
  }

  .inputOverskrifter {
    font-size: 1.0rem !important;
  }

  .patternPreviewDone {
    margin: 0px 0px;
  }
  #patternPreviewDoneDiv {
    height: 400px;
    width: 400px;
  }


}
@media (max-width: 1100px) {
.startChoice {
  display: grid;
    align-items: center;
    justify-content: center;
}
}

@media (max-width: 900px) {
  .broderistWrapperLeft {
    padding-left: 20px;
    padding-right: 20px;
  }
  .paddingSmallShowcase {
    padding-left: 20px;
    padding-right: 20px;
  }
  .showcaseStats {
    margin-top: 270px!important;
  }
  .showcaseImage {
    width: 250px!important;
    height: 250px!important;
  }
  .image-container-showcase {
    width: 250px;
    height: 250px;
  }

  .fadeContainer {
    width: 250px!important;
    height: 250px!important;
  }

  .trineCopyright {
    margin: auto;
    position: unset;
    color: black;
    margin-bottom: 80px;
  }
  .broderistWrapper {
    display: block;
    margin: auto;
    margin-bottom: 32px;
  }

  .broderistWrapperLeft {
    text-align: center;
    margin: auto;
  }

  .broderistText {
    margin: auto!important;
  }

  .broderistTextHead {
    margin: auto!important;
    margin-bottom: 21px!important;
  }


}

@media (max-width: 867px) {
  .buttonFilterMobile {
    display:block!important;
    font-size: 1rem!important;
    font-weight: 400!important;
    line-height: 1.4375em!important;
    letter-spacing: 0.00938em!important;
  }
  .categoryClass {
    max-width: 100%!important;
  }

  .searchBarCatalog {
    width: 100%!important;
  }
  .searchBarCatalogInput {
    width: 100%!important;
  }
  .searchDivCatalog {
    width: 100%!important;
  }
  .catalogWrapper {
    padding: 0;
    place-items: center;
    margin-top: 0px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .catalogFilterWrapper {
    visibility: hidden;
  }
  .topFilterWrapper {
    padding: 0px 50px 30px 30px!important;
  }
  .catalogMarginLeftDiv {
    margin: 0;
  }

  .topSortWrapper {
    padding: 30px 30px 0px 30px!important;
  }
}

@media (max-width: 560px) {
  .favoriteButton {
    width: calc(15vw / 2 - 0px);
    height: calc(15vw / 2 - 0px);
  }
  .favoriteButton svg {
    width: calc(9vw / 2 - 0px)!important;
    height: calc(9vw / 2 - 0px)!important;
  }
  .favoriteButtonFavorited {
    width: calc(15vw / 2 - 0px);
    height: calc(15vw / 2 - 0px);
  }
  .favoriteButtonFavorited svg {
    width: calc(9vw / 2 - 0px)!important;
    height: calc(9vw / 2 - 0px)!important;
  }
  .catalogSkeleton {
    width: calc(100vw / 2 - 70px)!important;
    height: calc(100vw / 2 - 70px)!important;
  }
  .catalogItemLoadWrapper {
    place-items: center;
    width: 100%;
    height: 59vw!important;
  }
  .catalogItemPaper {
    width: 100%!important;
    height: 59vw!important;
  }
  .catalogWrapper {
    grid-template-columns: repeat(auto-fill, minmax(40%,1fr));
  }

  .catalogItemImageWrapper {
    width: calc(100vw / 2 - 70px);
    height: calc(100vw / 2 - 70px);
    margin: auto;
  }

  .catalogItemImage {
    width:100%;
    height: 100%;
  }

  .catalogItemImageHideOnHover {
    width:100%;
    height: 100%;
  }
  .catalogItemTitle {
    font-size: 2.5vw!important;
    width: 100%!important;
  }

  .catalogItemText {
    font-size: 2.1vw!important;

  }
}
@media (max-device-width: 560px) {
  .catalogItemTitle {
    font-size: 3vw!important;
    width: 100%!important;
  }
  .favoriteButton {
    opacity: 1;

  }

  .favoriteButtonFavorited {
    opacity: 1;

  }


}

@media (max-device-width: 840px) {
  .catalogItemSelector:hover + div {
    top: 10px;
    opacity: 1;
  }


  
  .favoriteButton:hover {
    top: 10px;
    opacity: 1;
  }
  .favoriteButton {
    opacity: 1;
    top: 10px!important;
  }
  .favoriteButtonFavorited:hover {
    top: 10px;
    opacity: 1;
  }
  .favoriteButtonFavorited {
    opacity: 1;
    top: 10px!important;
  }
  .catalogItemImage {
    filter: none;
  }
  .catalogItemPageWrapper {
    display: grid;
  }
  .catalogItemPageImageWrapper {
    width: calc(150vw / 2 - 0px);
    height: calc(150vw / 2 - 0px);
    margin: auto;
  }
.catalogItemPageImageWraper {
  width: 100%;
  margin: 0;
  padding: 20px;
  height: 100%;
}
.catalogItemPageImage {
  width: calc(150vw / 2 - 0px);
  height: calc(150vw / 2 - 0px);
}
.splide {
  height: calc(150vw / 2 - 0px);
}
.catalogItemPageEdit {
  position: absolute;
  visibility: hidden;
}
.catalogItemPageText {
  width: 100%!important;
  padding: 20px;
}
.catalogItemPageNavigation {
  margin-top: -10px;
  height: 60px;
}
.commentsWrapper {
  position: absolute;
  visibility: hidden;
}
}

@media (max-width: 720px) {
  
  .overskriftHome {
    font-size: 2.5rem!important;
    margin-right: 0px;
  }
  .broderistTextHead {
    font-size: 2.5rem!important;
  }

  .textHome {
    font-size: 1rem!important;
  }
  .paddingSmallShowcase {
    padding-top: 32px!important;
    padding-bottom: 16px!important;
  }
  .broderistWrapperLeft {
    padding-top: 32px!important;
    padding-bottom: 32px!important;
  }

  .korsstingHjerte {
    font-size: 50px;
  }

  .paddingBottomShowcase {
    padding-bottom: 48px!important;
  }

  .featuresHomeCards {
    height: 200px;
    
  }

  .featuresHomeCardsGrid {
    padding-top: 30px;
  }

  .trineCopyright {
    margin-bottom: 32px;
  }

  .marieSytrådWrapper {
    display: none;
  }
  
  .trine {
    width: 300px;
  }
  .homeButtons {
    display: grid;
    grid-gap: 20px;
  }
  .homeSubtext {
    padding: 0px 20px!important;
  }
  .showcaseStats {
    margin-top: 170px!important;
  }
  .showcaseImage {
    width: 150px!important;
    height: 150px!important;
  }

  .image-container-showcase {
    width: 150px;
    height: 150px;
  }

  .fadeContainer {
    width: 150px!important;
    height: 150px!important;
  }

  .savedPatternPreviewImage {
    width: 200px;
  }
  .savedPatternPreviewImageWrapper {
    width: 200px;
    height: 200px;
  }

  .infoTableDividerSavedPattern {
    margin-right: -124px !important;
  }
 .infoTableSavedPattern {
  font-size: 12px;
  max-width: 250px;
 }
}

@media screen and (max-device-width: 900px) {
  #orText {
    position: absolute;
    visibility: hidden;
  }
  #createBlankCanvasButton {
    position: absolute;
    visibility: hidden;
  }
  .topNavigationWrapper {
    display: none!important;
  }
  .advancedEditButtonDiv {
    display: none!important;
  }
  #overskriftDrop {
    display: none!important;
  }
  body {
    height: 0px!important;
  }
    .dropWrapper {
    /* top: 300px!important; */
    top: 50%!important;
  }  
  .hovedKnapper {
    padding-bottom: 0px!important;
  }
  .overskrift {
    margin: 20px auto 0px auto;
    
  }
  .garnOverskrift {
    margin-top: 24px!important;
  }
  .infoTableRow {

    font-size: 12px;
  }
  .infoTable {
    padding: 20px;
  }
  .infoTableDivider {
    margin-left: 0 !important;
    margin-right: -180px !important;
  }
  .dmcTablePaper {
    margin: 0px auto 140px auto;
    max-width: 400px;
    width: var(--widthAndHeightSmall);
    min-width: 350px !important;
  }

  .savedPatternsTablePaper {
    margin: 0px auto 140px auto;
    max-width: 400px;
    width: var(--widthAndHeightSmall);
    min-width: 350px !important;
  }


  .dmcTableContainer {
    max-width: 400px;
    min-width: 350px !important;
    width: var(--widthAndHeightSmall)!important;
    height: 280px;
  }

  .konfiguration {
    display: none;
  }
  .sliderReset {
    top: 0px;
    left: 67px;
  }
  #overskriftTekst {
    display: none;
  }
  .overskriftTekstSmall {
    display: block;
  }
  .underskriftTekstSmall  {
    display: initial;
  }
  #underskriftTekst {
    display: none;
  }

  .smallKonfiguration {
    display: flex;
    justify-content: center;
    width: 394px;
    margin: 24px auto 0px auto;
  }
  .doneButtonLarge{
    display: none!important;
  }
  .doneButtonSmall {
    display: inline-flex!important;
    width: 132px!important;
    position: initial!important;
  }
  .buttonSmallWrapper {
    position: initial!important;
  }
.beskærOgResultat {
  display: block;
  margin: auto;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -52%);
}


.konfiguration {
  position: absolute;
}
#patternPreviewDoneDiv {
  height: var(--widthAndHeightSmall);
  width: var(--widthAndHeightSmall);
  max-width: 400px;
  max-height: 400px;
}

.quantCanvasBackground {
  width: var(--widthAndHeightSmall);
  height: var(--widthAndHeightSmall);
  max-width: 400px;
  max-height: 400px;
}

#quantDiv {
  width: var(--widthAndHeightSmall);
  height: var(--widthAndHeightSmall);
  max-width: 400px;
  max-height: 400px;
}

.resultCard {
  margin: auto auto 0px auto;
  width: var(--widthAndHeightSmall);
  max-width: 400px;
}


.quantCanvas {
  width: var(--widthAndHeightSmall);
  height: var(--widthAndHeightSmall);
  max-width: 400px;
  max-height: 400px;
}

.upscaledCanvas1to1 {
  width: calc(var(--cropWidth)*(var(--widthAndHeightSmall)/100));
  height: calc(var(--cropHeight)*(var(--widthAndHeightSmall)/100));
  max-width: calc(var(--cropWidth)*4px);
  max-height: calc(var(--cropHeight)*4px);
}


.loadingDiv {
  height: var(--widthAndHeightSmall);
  width: var(--widthAndHeightSmall);
  max-width: 400px;
  max-height: 400px;
}
.errorDiv {
  height: var(--widthAndHeightSmall);
  width: var(--widthAndHeightSmall);
  max-width: 400px;
  max-height: 400px;
}

.box {
  min-height: 0px!important;
}

.beskær {
  position: absolute!important;
  visibility: hidden!important;

}

.oversigtTop {
  display: block;
  height: fit-content;
}
.hovedKnapperBack {
  display: block;
}
.hovedKnapper {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 20px;
  width: 90%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
  margin: 0px!important;
  z-index: 3;
}
.stickyHovedKnapper {
  position: fixed;
  
}
.cropperClass {
  height: var(--widthAndHeightSmall);
  width: var(--widthAndHeightSmall);
  max-width: 400px;
  max-height: 400px;
}
.savedPatternsDropItemWrapper3 {
  width: 240px!important;
}

.removeThirdOnMobile {
  display: none;
}

}

/* @media (max-device-height: 815px) {

  .dropWrapper {
    top: 440px !important;
  }
} */

@media screen and (max-device-width: 500px) {
  .formControlInput {
    margin: 2px !important;
    max-width: 114px;
  }
  .selectInput {
    width: 114px;
  }
  .infoTableDivider {
    margin-right: -36vw !important;
  }
  .sliderReset {
    left:70px;
  }
  .smallKonfiguration {
    width: 80vw;
  }
  .overskriftTekst {
    font-size: 4.7vw!important;
    
  } 
  .overskriftTekstSmall {
    font-size: 4.7vw!important;
    
  } 
  .underskriftTekst {
      font-size: 2.9vw!important;
  }

  .underskriftTekstSmall {
    font-size: 2.9vw!important;
}
  
  .cardOverskrifter {
    font-size: 3.8vw!important;
  }
}
@media screen and (max-device-width: 450px) {


  .sliderReset {
    left: 66px;
  }

.rotateSlider {
  width: 256px;
}

}

@media screen and (max-device-height: 700px) {
  .mobileOverskrift {
      margin-bottom: 0px!important;
  }
  .smallKonfiguration {
    margin: 6px auto 0px auto;
  }
  
}

@media screen and (max-device-width: 350px) {
  .sliderReset {
    left: 66px;
  }

.rotateSlider {
  width: 200px;
}
  .korsstingOverskift {
    font-size: 7vw!important;
    margin-left: 1vw!important;
  } 
  .fabrikkenTekst {
      font-size: 6vw!important;
  }
  
}

.ekstraTilføjTilKurven {
  display: flex;
  justify-content: center;
  align-items: center;
}

#pdfProgress {
  font-size: x-large;
  font-weight: 500;
  color: rgb(233, 30, 99);
  position: absolute;
  left: 50.5%;
  top: 43.5%;
  transform: translate(-50%, -50%);
}

.pdfTable {
  page-break-inside: avoid;
}

.pdfTable,
.pdfTableRow {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 1px 2px;
  font-size: 12px;
  line-height: 10px;
  margin:auto
}

.pdfTableBig,
.pdfTableRowBig {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 1px 2px;
  line-height: 10px;
}

#pdfTableColor {
  padding: 0px !important;
}

.pdfStitchLabelLeft {
  position: relative;
  top: -28px;
  left: -30px;
  text-align: right;
  color: black;
  font-weight: bold;
  float: right;
}

.pdfStitchLabelEndLeft {
  position: relative;
  top: -14px;
  left: -30px;
  text-align: right;
  color: black;
  font-weight: bold;
  float: right;
}

.pdfStitchLabelRight {
  position: relative;
  top: -28px;
  left: 35px;
  text-align: right;
  color: black;
  font-weight: bold;
  float: right;
}

#pointerLabelTop {
  top: -70px !important;
}

.cookieSwitchesWrapper {
  display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 16px;
    margin-bottom: -4px;
}

.cookieButtons {
  display: grid!important;
  justify-content: initial!important;
  margin: auto;
  width: 100%;
  min-width: 234px;
  padding: 12px 20px!important;
}

.disabledSwitch {
  cursor: not-allowed!important;
  pointer-events: none;
}

.disabledSwitchDiv {
  cursor: not-allowed;
  width: fit-content;
}

#downloadPDFDiv {
  visibility: hidden;
  /* padding: 50px;
  width: fit-content;
  margin: auto; */
}

.overflowHidden {
   overflow: hidden!important; 
}


#pointerLabelLeft {
  left: -47px !important;
}

#pdfDiv {
  /* grid-gap: 200px;
  margin: auto;
  width: min-content; */
  display: grid;

  grid-template-rows: repeat(var(--grid-rows), 1fr);
  grid-template-columns: repeat(var(--grid-cols), 1fr);
}




.cellRedRight {
  border-right: 2px solid red;
}

.cellRedLeft {
  border-left: 2px solid red;
}

.cellRedTop {
  border-top: 2px solid red;
}

.cellRedBottom {
  border-bottom: 2px solid red;
}


.cellBlackRight {
  border-right: 2px solid black;
}

.cellBlackLeft {
  border-left: 2px solid black;
}

.cellBlackTop {
  border-top: 2px solid black;
}

.cellBlackBottom {
  border-bottom: 2px solid black;
}



.parentDiv {
/*    border: 2px solid black; 

  margin: 5px auto;
  width: min-content;
/*   display: grid;
  grid-gap: 1px;
  background-color: black;
  grid-template-rows: repeat(var(--grid-rows), 1fr);
  grid-template-columns: repeat(var(--grid-cols), 1fr);  */

}

.dropWrapper {
  margin-top: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.grid-item {
  padding: 0px;
  width: 16px;
  height: 16px;
  background-color: white;
  text-align: center;
}



.infoTableRow {

  width: 50%;
}




.inputKnapper {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}




.MuiTableRow-root {
  /* background-color: rgba(233, 30, 99, 0.08) !important; */
}

.konfigurationsOverskrift {
  display: flex;
  align-items: center;
}

.MuiTableRow-root:hover {
  /* background-color: rgba(233, 30, 99, 0.12) !important; */
}

.beskærOgResultatOverskrift {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.no-selection {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently*/
}

tr.Mui-selected {
  background: none!important;
  /* background-color: rgba(255, 255, 255, 0.08) !important; */
}

tr.Mui-selected:hover {
  background-color: rgba(200, 200, 200, 0.12) !important;
}

.MuiTableRow-head,
.MuiTableRow-head:hover {
 /*  background-color: rgba(255, 255, 255, 0.3) !important; */
}


.fadeContainer {
  width: 300px;
  height: 300px;
}


.showcaseImage {
/*    position: absolute; */
  width: 300px;
  height: 300px;
  image-rendering: pixelated;
  object-fit: contain;
/*   border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.2);  */
}

.showcaseImageWrapper {
  /* background-color: white; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}



.root {
  padding: 5 !important;
}

.hiddenDiv {
  display: none;
  position: absolute;
}

:root {
  --primary: rgb(233, 30, 99);
  --primary2: rgb(116 93 240);
  --hover-brightness: 1.2;
  --grid-cols: 1;
  --previewgrid-cols: 1;
  --grid-rows: 1;
  --widthAndHeightSmall: 80vw;
}

.konfiguration {
  margin: 14px 20px 20px 20px;
}



.drawerKonfiguration {
    padding: 14px 0px 0px 0px;
}
body {
  font-family: 'Poppins', sans-serif;
  background-color: rgb(240, 242, 245);
  transition: background-color 100ms ease-in 0s; 
  height: 100vh;
}

.korsstingOverskift {
  font-family: stitchFont;
  font-size: 30px;
  color: var(--primary);
  margin-left: 16px;
}


/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
  display: none;
}

.selectDiv {
  position: relative;
  margin: auto;
  /*Don't really need this just for demo styling*/

}

.dd-list {
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2) !important;
}

.dd-list-item:hover {
  color: black !important;
  background-color: rgb(224, 224, 224) !important;
}


.button {
  margin: 8px !important;
  /*cursor: pointer;
  color: white;
  background-color: var(--primary2);
  border: none;
  border-radius: 12px;*/
  padding: 8px 16px;
  /*box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: all .5s;*/
}

/*button:hover
{
  filter: brightness(var(--hover-brightness));
}*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


#createImgButton {
  font-size: 24px;
  font-weight: bolder;
  border-radius: 18px;
  padding: 12px 18px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
}



.upscaledCanvasPalette {
  z-index: -1;
  width: 400px;
  height: 200px;
  margin-bottom: -150px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}



.box {
  min-height: 600px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
  background-color: none;
}

.ReactCrop:focus {
  outline: none;
}

.ReactCrop--disabled {
  cursor: inherit;
}

.ReactCrop__image {
  display: block;
  max-width: 100%;
  max-height: -webkit-stretch;
  max-height: -moz-available;
  max-height: stretch;
}

.ReactCrop--crop-invisible .ReactCrop__image {
  opacity: 0.5;
}

.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(255, 255, 255, 0.5);
  border: 1px solid;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  border-image-slice: 1;
  border-image-repeat: repeat;
}

.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}

.ReactCrop__drag-handle {
  position: absolute;
  border-radius: 64px;
  width: 9px;
  height: 9px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 255, 255, 1);
  box-sizing: border-box;
  outline: 1px solid transparent;
}

.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}

.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}

.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}

.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}

.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}

.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}

.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}

.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}

.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}

.ReactCrop__drag-bar {
  position: absolute;
}

.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}

.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}

.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}

.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}

.ReactCrop--new-crop .ReactCrop__drag-bar,
.ReactCrop--new-crop .ReactCrop__drag-handle,
.ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}

.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}

@media (max-width: 768px),
(pointer: coarse) {

  .ReactCrop__drag-handle {
    width: 17px;
    height: 17px;
  }

  .ReactCrop .ord-nw {
    margin-top: -9px;
    margin-left: -9px;
  }

  .ReactCrop .ord-n {
    margin-top: -9px;
    margin-left: -9px;
  }

  .ReactCrop .ord-ne {
    margin-top: -9px;
    margin-right: -9px;
  }

  .ReactCrop .ord-e {
    margin-top: -9px;
    margin-right: -9px;
  }

  .ReactCrop .ord-se {
    margin-bottom: -9px;
    margin-right: -9px;
  }

  .ReactCrop .ord-s {
    margin-bottom: -9px;
    margin-left: -9px;
  }

  .ReactCrop .ord-sw {
    margin-bottom: -9px;
    margin-left: -9px;
  }

  .ReactCrop .ord-w {
    margin-top: -9px;
    margin-left: -9px;
  }

  .ReactCrop__drag-bar.ord-n {
    height: 14px;
    margin-top: -7px;
  }

  .ReactCrop__drag-bar.ord-e {
    width: 14px;
    margin-right: -7px;
  }

  .ReactCrop__drag-bar.ord-s {
    height: 14px;
    margin-bottom: -7px;
  }

  .ReactCrop__drag-bar.ord-w {
    width: 14px;
    margin-left: -7px;
  }

}