.header {
    padding: 12px 0;
    text-align: center;
    background: rgb(255, 255, 255);
    box-shadow: 0 4px 12px rgba(0,0,0,0.2);
    display: flex;
    justify-content: right;
  }
  .header button {
      margin-right: 100px;
  }