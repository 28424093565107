@font-face {
    font-family: stitchFont;
    src: url(HovdenStitch-YzEvo.ttf);
  }

.headsection {
    
    width: 100%;
    height: 550px;
    /*box-shadow: inset 0px -10px 15px rgb(0 0 0 / 10%);*/
}
/* .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 500%;
    height: 100%;
    animation: animate 10s linear infinite;
  }
  
  .slider .slide {
    position: relative;
    width: 20%;
    height: 100%;
    float: left;
  }

  .container .slider .slide.slide1 {
    background: url(../images/slide1.jpg);
    background-size: cover;
    background-position: center;
  }
  
  .container .slider .slide.slide2 {
    background: url(../images/slide2.jpg);
    background-size: cover;
    background-position: center;
  }
  
  .container .slider .slide.slide3 {
    background: url(../images/slide3.jpg);
    background-size: cover;
    background-position: center;
  }
  
  .container .slider .slide.slide4 {
    background: url(../images/slide4.jpg);
    background-size: cover;
    background-position: center;
  } */

#bgslider { 
	overflow: hidden; 
    height: inherit;
}

#bgslider figure { 
    position: relative;
    width: 500%;
    margin: 0;
    left: 0;
    text-align: left;
    font-size: 0;
    animation: 40s slidy infinite; 
  }

  #bgslider figure img { 
	width: 20%; 
	float: left; 
    filter: opacity(40%);
}

.sådanFungererDetWrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.sådanFungererDetVenstre {
    width: 75px;
}

.sådanFungererDetHøjre {
    width: 150px;
}

.sådanFungererDetItem {
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 6px;
}

@keyframes slidy {
    0% {
        left: 0;
    }
    20% {
        left: 0;
    }
    25% {
        left: -100%;
    }
    45% {
        left: -100%;
    }
    50% {
        left: -200%;
    }
    70% {
        left: -200%;
    }
    75% {
        left: -300%;
    }
    95% {
        left: -300%;
    }
    100% {
        left: -400%;
    }
  }

.headsectionWrapper {
    height: inherit;
    margin: auto;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    max-width: 1000px;
}
.korsstingOverskiftHjem {
    font-family: stitchFont;
    font-size: 80px;
    color: var(--primary);
    margin: 0;
    line-height: 60px;
}


.overskriftHjemWrapper {
text-align: center;
max-width: 434px;
}

.displayWrapper {
    max-width: 360px;
    max-height: 400px;
}

@media screen and (max-width: 1000px) {
    #bgslider figure img { 
        height: 40vh;
    }
}

@media screen and (max-width: 900px) {
    .displayWrapper {
        display: none;
    }
}

.cb-slideshow,
.cb-slideshow:after { 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: -100; 
}
.cb-slideshow:after { 
    content: '';
}

.cb-slideshow li span { 
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: none;
    opacity: 1;
    filter: opacity(20%) blur(1px);
    z-index: 0;
/*     animation: imageAnimation 12s linear infinite 0s;  */
}

.cb-slideshow li:nth-child(1) span { 
    background-image: url(../images/slide2.jpg) 
}
/* .cb-slideshow li:nth-child(2) span { 
    background-image: url(../images/slide2.jpg);
    animation-delay: 6s; 
} */
/* .cb-slideshow li:nth-child(3) span { 
    background-image: url(../images/slide3.jpg);
    animation-delay: 12s; 
}
.cb-slideshow li:nth-child(4) span { 
    background-image: url(../images/slide4.jpg);
    animation-delay: 18s; 
}
.cb-slideshow li:nth-child(5) span { 
    background-image: url(../images/slide5.jpg);
    animation-delay: 24s; 
}
 */

@keyframes imageAnimation { 
    0% { opacity: 0; animation-timing-function: ease-in; }
    8% { opacity: 1; animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}

.no-cssanimations .cb-slideshow li span{
	opacity: 1;
}